/* Coverage:
 Statements: 18%
 Branches: 100%
 Functions: 25%
 (Generated by scripts/generate-coverage-comments.js - Do not edit this comment)
*/
import * as Sentry from '@sentry/react'
class SentryService {
  private initialized: boolean = false
  private errorLog = new Map<string, Date>()
  public initialize(runEnv: string): void {
    if (this.initialized) {
      console.warn('Sentry has already been initialized')
      return
    }
    Sentry.init({
      dsn: 'https://2bcdb1be7bd1c59916d4dbcfbadd07c4@o4507442722242560.ingest.us.sentry.io/4507442725781504',
      environment: runEnv,
      debug: false,
    })
    this.initialized = true
  }
  public isInitialized(): boolean {
    return this.initialized
  }
  public logError(err: any, limitToDaily = true, extraInfo?: Record<string, any>) {
    if (!this.initialized) {
      return
    }
    if (limitToDaily) {
      const key = err instanceof Error ? `${err.message}:${err.stack?.split('\n')[1] || ''}` : `${err}`
      const now = new Date()
      if (!(key in this.errorLog) || now.getTime() - (this.errorLog.get(key) || now).getTime() >= 86400000) {
        Sentry.withScope(scope => {
          if (extraInfo) {
            Object.entries(extraInfo).forEach(([key, value]) => {
              scope.setExtra(key, value)
            })
          }
          scope.setTag('daily_limited', 'true')
          Sentry.captureException(err)
        })
      }
      this.errorLog.set(key, now)
    } else {
      Sentry.withScope(scope => {
        if (extraInfo) {
          Object.entries(extraInfo).forEach(([key, value]) => {
            scope.setExtra(key, value)
          })
        }
        scope.setTag('daily_limited', 'false')
        Sentry.captureException(err)
      })
    }
  }
}
export const sentryService = new SentryService()
